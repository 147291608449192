import { i18n } from '@lingui/core'; // import {
//   en,
//   fr,
//   de,
//   ru,
//   zh,
//   pt,
//   nl,
//   es,
//   it,
//   tr,
//   cs,
//   hu,
//   ko,
// } from 'make-plural/plurals';
// The default locale of the site

export const defaultLocale = 'en'; // All the available locales for the site, must include the default one.

export const localesTranslations = {
  en: 'English',
  // Anglais
  fr: 'Français',
  //Francais
  de: 'Deutsch',
  // Allemand
  ru: 'Русский',
  // Russe
  zh: '简体中文',
  // Chinois,
  pt: 'Português',
  // Brésilien (Portugais-BR), see https://www.npmjs.com/package/make-plural, pt is brazil, pt_PT is portugal
  nl: 'Nederlands',
  // Néerlandais
  es: 'Español',
  // Espagnol
  it: 'Italiano',
  // Italien
  tr: 'Türkçe',
  // Turc
  cs: 'Čeština',
  // Tchèque
  hu: 'Magyar',
  // Hongrois
  ko: '한국어' // Coréen

}; // i18n.loadLocaleData({
//   en: { plurals: en },
//   fr: { plurals: fr },
//   de: { plurals: de },
//   ru: { plurals: ru },
//   zh: { plurals: zh },
//   pt: { plurals: pt },
//   nl: { plurals: nl },
//   es: { plurals: es },
//   it: { plurals: it },
//   tr: { plurals: tr },
//   cs: { plurals: cs },
//   hu: { plurals: hu },
//   ko: { plurals: ko },
// });

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */

export async function dynamicActivate(locale) {
  try {
    const {
      messages
    } = await import(`../../../locales/${locale}/messages.po`);
    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (e) {
    console.error(e);
  }
}